<template>
  <div class="mx-auto p-4 max-w-screen-xl -mt-32">
    <div class="newsletter-container">
      <div>
        <span class="text-[1.8rem] mb-2 block font-bold leading-snug">{{
          newsletterStrapiData?.title
        }}</span>
        <span class="mb-4 block font-medium">{{
          newsletterStrapiData?.subtitle
        }}</span>
        <div
          class="flex flex-row min-[400px]:flex-row mt-4 md:my-4 border border-gray-300 rounded-md justify-between relative"
        >
          <input
            v-model="form.email"
            class="rounded-md min-h-[32px] ml-4 grow border-transparent focus:border-transparent focus-visible:outline-none"
            type="text"
            :placeholder="newsletterStrapiData?.placeholder"
            @input="$emit('clearErrors', 'email')"
          />
          <Button @click="handleSubscribe">{{
            newsletterStrapiData?.buttonLabel
          }}</Button>
          <span
            v-if="invalidKeys?.includes('email')"
            class="text-red text-sm absolute top-20 min-[400px]:top-12"
          >
            {{ errors?.email }}
          </span>
        </div>
        <span v-show="isEmailSubmitted" class="mt-2 text-primary text-sm">
          {{ $t('general.newsletter.successMessage') }}
        </span>
      </div>
      <div class="hidden md:flex gap-4">
        <div
          v-for="(media, index) in newsletterStrapiData?.media"
          :key="index"
          class="image-container"
        >
          <NuxtLink :to="media?.link" class="w-full h-full block">
            <NuxtImg
              :src="strapiImageGetters?.getImageUrl(media?.image?.data)"
              class="h-full object-cover rounded-xl"
            />
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { toast, type ToastOptions } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { useFormValidation } from '~/composables/useFormValidation'
const { locale } = useI18n()
const { data: newsletterStrapiResponse } = await useStrapiSingleType(
  `newsletter?locale=${locale.value}&populate=deep,4`
)
const newsletterStrapiData = computed(
  () => newsletterStrapiResponse.value?.data?.attributes
)
const form = ref<{ email: string }>({ email: '' })
const rules = ref({ email: 'required|email' })
const { errors, clearErrors, validate, invalidKeys } = useFormValidation(
  form,
  rules
)
const isEmailSubmitted = ref('')
const handleSubscribe = async () => {
  await validate()

  if (errors.value !== null && Object.keys(errors.value)?.length > 0) {
    return
  }
  try {
    await kftApiRequest(`/v1/store/customers/subscribe-newsletter`, {
      method: 'POST',
      body: { email: form.value.email },
    })

    isEmailSubmitted.value = true
  } catch (err) {
    toast(e.data?.message || t('general.error'), {
      autoClose: 2000,
      type: 'error',
      position: toast.POSITION.TOP_CENTER,
      multiple: false,
    } as ToastOptions)
  }
}
</script>
<style scoped lang="postcss">
.newsletter-container {
  @apply flex gap-8 justify-between items-center;
  @apply bg-white border border-gray-300;
  @apply translate-y-1/2;
  @apply rounded-xl;
  @apply p-4 md:p-8;
  box-shadow: 4px 4px 20px 30px #00000005;
}
.image-container {
  @apply hidden md:block;
  @apply w-52 h-48;
  @apply rounded-xl;
  @apply shadow-lime-500;
  box-shadow: -5px 5px 0px 0px var(--tw-shadow-color);

  &:nth-child(2) {
    @apply hidden lg:block;
  }
  &:nth-child(3) {
    @apply hidden xl:block;
  }
}
</style>
